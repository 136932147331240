<template>
  <div class="flex-auto column" style="margin-bottom: 0;">
    <div class="padding-bottom-10 border-bottom bold">我的评价</div>
    <div ref="main">
      <div class="padding-tb-10 border-bottom" v-for="(item,i) in listInfo.list" :key="i">

          <div class="flex-row">
            <div class="text-align-right margin-top-5 color-sub font-size-12">{{item.create_time}}</div>
            <!-- <div class="margin-left-10"><i v-for="(item,i) in 5" :key="i" class="el-icon-star-on color-orange"></i></div> -->
          </div>
          <div class="margin-top-5">{{item.pj_msg}}</div>
          <div class="flex-row flex-wrap">
            <div class="margin-5 link" @click="activedImgOfEvalShow=true;activedImgOfEval=item_img" v-for="(item_img,i_img) in item.img_list" :key="i_img"><el-image class="border" :src="item_img" fit="contain" style="width: 80px; height: 80px;"></el-image></div>
          </div>
          

      </div>

      <div v-if="listInfo.loading" class="text-align-center color-sub margin-tb-10"><i class="el-icon-loading"></i> 加载中</div>
      <div v-if="listInfo.noMore&&listInfo.list.length" class="text-align-center color-sub margin-tb-10">没有更多啦！</div>
      <el-empty v-if="!listInfo.list.length&&listInfo.page==1&&!listInfo.loading" description="暂无数据"></el-empty>
    </div>
    <el-dialog custom-class="custom-dialog" title=""  :visible.sync="activedImgOfEvalShow" :append-to-body="true" width="1100px" top="15vh">
      <div>
        <el-image class="border" :src="activedImgOfEval" fit="contain" style="width: 100%; height: calc(100vh - 100px - 30vh);"></el-image>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import crypto from "@/common/crypto";
export default {
  components: { },
  name: 'BrowsingHistory',
  data() {
    return {
      crypto,
      listInfo:{
        loading:false,
        noMore:false,
        page:1,
        pageSize:20,
        list:[]
      },
      activedImgOfEvalShow:false,
      activedImgOfEval:'',
      bodyScrollHeight:0
    }
  },
  computed:{
    
  },
  methods:{
    //获取列表
    getList(){
      this.listInfo.loading=true
      let params= {
        ...this.$store.state.basicParams,
        page:this.listInfo.page,
        page_size:this.listInfo.pageSize,
      }
      this.$instance.get('/product/get_my_pj_list',{params})
      .then(res=>{
        this.listInfo.loading=false
        if(res.data.code==0){
          this.listInfo.list=this.listInfo.list.concat(res.data.data.list)
          this.$nextTick(()=>{
            window.scrollTo(0,this.bodyScrollHeight)
          })
          //是否全部加载完成
          if(res.data.data.list.length<this.listInfo.pageSize){
            this.listInfo.noMore=true
          }else{this.listInfo.page+=1}
          //如果有更多增加滚动加载侦听
          if(this.listInfo.page==2){
            document.addEventListener('scroll', this.loadMore)
          }
        }
      })
      .catch(()=> {
        this.listInfo.loading=false
      }); 
    },
    //加载更多
    loadMore(){
      this.bodyScrollHeight=document.documentElement.scrollTop || document.body.scrollTop
      this.$root.scrollLoad({
        dom:this.$refs.main,
        allow:!this.listInfo.loading&&!this.listInfo.noMore,
        offset:0,
        callBack:this.getList,
      })
    },
  },
  created(){
    this.getList()
  },
  mounted(){ 
    
  },
  beforeDestroy() {
    document.removeEventListener("scroll", this.loadMore);
  },
  watch: {
    
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
 .list-item-pic{
    width: 60px;
    height: 60px;
    border-radius: 5px;
    background: #fff;
    overflow: hidden;
  }
</style>
<style>

</style>
